//FIXME: TOKEN
import aaveLogo from 'packages/tokens/img/aave.svg';
import adaLogo from 'packages/tokens/img/ada.svg';
import ageurLogo from 'packages/tokens/img/agEUR.svg';
import alpacaLogo from 'packages/tokens/img/alpaca.png';
import angleLogo from 'packages/tokens/img/angle.svg';
import ankrLogo from 'packages/tokens/img/ankr.svg';
import ankrbnbLogo from 'packages/tokens/img/ankrBNB.svg';
import bchLogo from 'packages/tokens/img/bch.svg';
import bethLogo from 'packages/tokens/img/beth.svg';
import bifiLogo from 'packages/tokens/img/bifi.png';
import bnbLogo from 'packages/tokens/img/bnb.svg';
import bnbxLogo from 'packages/tokens/img/bnbx.png';
import bswLogo from 'packages/tokens/img/bsw.svg';
import btcbLogo from 'packages/tokens/img/btcb.svg';
import bttLogo from 'packages/tokens/img/btt.svg';
import busdLogo from 'packages/tokens/img/busd.svg';
import cakeLogo from 'packages/tokens/img/cake.svg';
import daiLogo from 'packages/tokens/img/dai.svg';
import dogeLogo from 'packages/tokens/img/doge.svg';
import dotLogo from 'packages/tokens/img/dot.svg';
import ethLogo from 'packages/tokens/img/eth.svg';
import filLogo from 'packages/tokens/img/fil.svg';
import flokiLogo from 'packages/tokens/img/floki.svg';
import hayLogo from 'packages/tokens/img/hay.png';
import linkLogo from 'packages/tokens/img/link.svg';
import ltcLogo from 'packages/tokens/img/ltc.svg';
import lunaLogo from 'packages/tokens/img/luna.svg';
import maticLogo from 'packages/tokens/img/matic.svg';
import nftLogo from 'packages/tokens/img/nft.png';
import planetLogo from 'packages/tokens/img/planet.svg';
import racaLogo from 'packages/tokens/img/raca.png';
import satLogo from 'packages/tokens/img/sat.svg';
import sdLogo from 'packages/tokens/img/sd.svg';
import snbnbLogo from 'packages/tokens/img/snBNB.svg';
import stkbnbLogo from 'packages/tokens/img/stkBNB.svg';
import sxpLogo from 'packages/tokens/img/sxp.svg';
import theLogo from 'packages/tokens/img/the.svg';
import trxLogo from 'packages/tokens/img/trx.svg';
import tusdLogo from 'packages/tokens/img/tusd.svg';
import twtLogo from 'packages/tokens/img/twt.svg';
import uniLogo from 'packages/tokens/img/uni.svg';
import usdcLogo from 'packages/tokens/img/usdc.svg';
import usddLogo from 'packages/tokens/img/usdd.svg';
import usdtLogo from 'packages/tokens/img/usdt.svg';
import ustLogo from 'packages/tokens/img/ust.svg';
import vaiLogo from 'packages/tokens/img/vai.svg';
import vrtLogo from 'packages/tokens/img/vrt.svg';
import wbethLogo from 'packages/tokens/img/wbeth.svg';
import wbnbLogo from 'packages/tokens/img/wbnb.svg';
import winLogo from 'packages/tokens/img/win.svg';
import xrpLogo from 'packages/tokens/img/xrp.svg';
import xvsLogo from 'packages/tokens/img/xvs.svg';
import { Token } from 'types';

export const tokens: Token[] = [
  //IONICS

  {
    //cIonUSDC
    address: '0x760B91B3Cf8C63600076B800928205f4DDC2C412',
    decimals: 6,
    symbol: 'WETH',
    asset: ethLogo,
  },
  {
    //cIonUSDT
    address: '0x729cE7EA8068a2F4C3311bd80Ed559D17A9531Bd',
    decimals: 6,
    symbol: 'WETH',
    asset: ethLogo,
  },
  {
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    symbol: 'WETH',
    asset: ethLogo,
  },
  {
    address: '0x0000000000000000000000000000000000000000',
    decimals: 18,
    symbol: 'ETH',
    asset: ethLogo,
    isNative: true,
  },
  {
    address: '0x054445a65Cf8B06bcba311a702E50cf0f5D76E6f',
    decimals: 8,
    symbol: 'WBTC',
    asset: btcbLogo,
  },
  {
    address: '0x88c612FeC63065BA1E53eBaDA2d5d04C55AE6B77',
    decimals: 18,
    symbol: 'SAT',
    asset: satLogo,
  },
  {
    address: '0x22198B46C84Cf43831E65D32a9403A194D617a61',
    decimals: 8,
    symbol: 'USDC',
    asset: usdcLogo,
  },
  {
    address: '0x9509993a28237c023aA3f95DdfBC917A572299E6',
    decimals: 8,
    symbol: 'USDT',
    asset: usdtLogo,
  },
  {
    address: '0x1401e3b6857d8c6e82f2aabc88c0dba24faf3dfc',
    decimals: 18,
    symbol: 'WXRP',
    asset: xrpLogo,
  },
  {
    address: '0xd9d958d797574fd343acefc9406983455c494380',
    decimals: 18,
    symbol: 'LINK',
    asset: linkLogo,
  },
];
