//FIXME: TOKEN
import aaveLogo from 'packages/tokens/img/aave.svg';
import adaLogo from 'packages/tokens/img/ada.svg';
import ageurLogo from 'packages/tokens/img/agEUR.svg';
import alpacaLogo from 'packages/tokens/img/alpaca.png';
import angleLogo from 'packages/tokens/img/angle.svg';
import ankrLogo from 'packages/tokens/img/ankr.svg';
import ankrbnbLogo from 'packages/tokens/img/ankrBNB.svg';
import bchLogo from 'packages/tokens/img/bch.svg';
import bethLogo from 'packages/tokens/img/beth.svg';
import bifiLogo from 'packages/tokens/img/bifi.png';
import bnbLogo from 'packages/tokens/img/bnb.svg';
import bnbxLogo from 'packages/tokens/img/bnbx.png';
import bswLogo from 'packages/tokens/img/bsw.svg';
import btcbLogo from 'packages/tokens/img/btcb.svg';
import bttLogo from 'packages/tokens/img/btt.svg';
import busdLogo from 'packages/tokens/img/busd.svg';
import cakeLogo from 'packages/tokens/img/cake.svg';
import daiLogo from 'packages/tokens/img/dai.svg';
import dogeLogo from 'packages/tokens/img/doge.svg';
import dotLogo from 'packages/tokens/img/dot.svg';
import ethLogo from 'packages/tokens/img/eth.svg';
import filLogo from 'packages/tokens/img/fil.svg';
import flokiLogo from 'packages/tokens/img/floki.svg';
import hayLogo from 'packages/tokens/img/hay.png';
import ionUSDCLogo from 'packages/tokens/img/ionUSDC.svg';
import ionUSDTLogo from 'packages/tokens/img/ionUSDT.svg';
import linkLogo from 'packages/tokens/img/link.svg';
import ltcLogo from 'packages/tokens/img/ltc.svg';
import lunaLogo from 'packages/tokens/img/luna.svg';
import maticLogo from 'packages/tokens/img/matic.svg';
import nftLogo from 'packages/tokens/img/nft.png';
import planetLogo from 'packages/tokens/img/planet.svg';
import racaLogo from 'packages/tokens/img/raca.png';
import satLogo from 'packages/tokens/img/sat.svg';
import sdLogo from 'packages/tokens/img/sd.svg';
import snbnbLogo from 'packages/tokens/img/snBNB.svg';
import stkbnbLogo from 'packages/tokens/img/stkBNB.svg';
import sxpLogo from 'packages/tokens/img/sxp.svg';
import theLogo from 'packages/tokens/img/the.svg';
import trxLogo from 'packages/tokens/img/trx.svg';
import tusdLogo from 'packages/tokens/img/tusd.svg';
import twtLogo from 'packages/tokens/img/twt.svg';
import undLogo from 'packages/tokens/img/und.svg';
import uniLogo from 'packages/tokens/img/uni.svg';
import usdcLogo from 'packages/tokens/img/usdc.svg';
import usddLogo from 'packages/tokens/img/usdd.svg';
import usdtLogo from 'packages/tokens/img/usdt.svg';
import ustLogo from 'packages/tokens/img/ust.svg';
import vaiLogo from 'packages/tokens/img/vai.svg';
import vrtLogo from 'packages/tokens/img/vrt.svg';
import wbethLogo from 'packages/tokens/img/wbeth.svg';
import wbnbLogo from 'packages/tokens/img/wbnb.svg';
import winLogo from 'packages/tokens/img/win.svg';
import xrpLogo from 'packages/tokens/img/xrp.svg';
import xvsLogo from 'packages/tokens/img/xvs.svg';
import { Token } from 'types';

export const tokens: Token[] = [
  //IONICS

  // {
  //   //cIonUSDC
  //   address: '0x86F4b1536381bC1436069F12561f2eC4FB46BB13',
  //   decimals: 6,
  //   symbol: 'WETH',
  //   asset: ethLogo,
  // },
  // {
  //   //cIonUSDT
  //   address: '0x4950AcE5E556318ED32C78afF6396fE0dED88c16',
  //   decimals: 6,
  //   symbol: 'WETH',
  //   asset: ethLogo,
  // },
  // {
  //   address: '0x4200000000000000000000000000000000000006',
  //   decimals: 18,
  //   symbol: 'WETH',
  //   asset: ethLogo,
  // },
  {
    address: '0x0000000000000000000000000000000000000000',
    decimals: 18,
    symbol: 'ETH',
    asset: ethLogo,
    isNative: true,
  },
  {
    address: '0xF51C8d31080a7939B8041A43FbD600B060c7281e',
    decimals: 18,
    symbol: 'SAT',
    asset: satLogo,
  },
  {
    address: '0x2BE717340023C9e14C1Bb12cb3ecBcfd3c3fB038',
    decimals: 6,
    symbol: 'ionUSDC',
    asset: ionUSDCLogo,
  },
  {
    address: '0x94812F2eEa03A49869f95e1b5868C6f3206ee3D3',
    decimals: 6,
    symbol: 'ionUSDT',
    asset: ionUSDTLogo,
  },
  {
    address: '0xD54E6095765e1D549d134B29Fee71e454461dF0c',
    decimals: 18,
    symbol: 'UND',
    asset: undLogo,
  },
];
