/* Automatically generated file, do not update manually */
export default {
  VenusLens: {
    34443: '0xBD05e121707b279284f69440D40Cfb6Bf708a37E',
  },
  PoolLens: {},
  PoolRegistry: {},
  LegacyPoolComptroller: {
    919: '',
    34443: '0x6bE8E982D13baBc4336F99179A9D145dBf87a0BA',
  },
  VaiController: {},
  VaiVault: {},
  XvsVault: {},
  XvsStore: {},
  GovernorBravoDelegate: {},
  XvsVesting: {},
  VrtConverter: {},
  Maximillion: {
    919: '',
    34443: '',
  },
  XsequenceMulticall: {
    919: '',
    34443: '',
  },
  Multicall3: {
    919: '0xBAba8373113Fb7a68f195deF18732e01aF8eDfCF',
    34443: '0x4c9d538dcb4DDDFBF21111563b83fe9D61E64904',
  },
  ResilientOracle: {
    919: '',
    34443: '0x33cFdBA1Ec98911d9f48151500498AC188cec695',
  },
  Prime: {},
  SwapRouter: {},
  Faucet: {
    919: '0xD778f6C33d0408117D955B198c8Ea92a030C50E9',
  },
};
