import { DisabledTokenAction } from '../../types';

export const disabledTokenActions: DisabledTokenAction[] = [
  {
    // UND
    address: '0xD54E6095765e1D549d134B29Fee71e454461dF0c',
    disabledActions: ['withdraw', 'supply'],
  },
  {
    // ionUSDC
    address: '0x2BE717340023C9e14C1Bb12cb3ecBcfd3c3fB038',
    disabledActions: ['borrow', 'repay'],
  },
  {
    // ionUSDT
    address: '0x94812F2eEa03A49869f95e1b5868C6f3206ee3D3',
    disabledActions: ['borrow', 'repay'],
  },
];
